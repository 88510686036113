import axios from "axios";

const SERVER_URL = "api/user";

const instance = axios.create({
    baseURL: SERVER_URL,
    timeout: 10000
})

export default {
    // (R)ead
    getAll: () => instance.get('', {
        transformResponse: [function (data) {
            return data ? JSON.parse(data) : []
        },]
    }),

    // (R)ead Full with pagination
    getAllFullPaging: (page, size, sortBy, sortDesc) => {
        const params = {page: page, size: size, projection: 'full'}
        if (sortBy !== undefined) {
            params.sort = sortBy
            if (sortDesc === true) {
                params.sort += ",desc"
            } else {
                params.sort += ",asc"
            }
        }

        return instance.get('', {
                params: params
            }
        )
    },

    // (D)elete
    removeForId: (id) => instance.delete('/' + id),

    // Add a new
    createNew: (item) => instance.post('', item),

    updateForId: (id, data) => instance.put('/update/' + id, data),

    updatePw: (data) => instance.put('/update/self', {password: data}),

}