<template>
    <v-container ma-0 fluid>

        <Help>Here, you can manage the users.</Help>

        <v-container fluid>
            <Error :error="error"/>
            <v-data-table
                    :headers="headers"
                    :items="data"
                    :items-per-page="10"
                    :loading="loading"
                    class="elevation-1"
            >
                <template v-slot:top>
                    <v-toolbar>
                        <v-toolbar-title>Users</v-toolbar-title>
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        />
                        <v-spacer/>
                        <v-dialog v-model="dialog" max-width="500px">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" outlined class="mb-2" v-on="on">
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <Error :error="editError"/>
                                    <v-alert type="info" v-if="editedIndex!==-1">
                                        If the passwords are left blank, the password will not be changed.
                                    </v-alert>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field v-model="editedItem.username"
                                                              label="Username" :disabled="editedIndex!==-1"/>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field v-model="editedItem.password" type="password"
                                                              :error="editedItem.password!==editedItem.password2"
                                                              label="Password"/>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field v-model="editedItem.password2" type="password"
                                                              :error="editedItem.password!==editedItem.password2"
                                                              label="Password again"/>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-switch v-model="editedItem.admin" class="ma-4" label="Admin"/>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-switch v-model="editedItem.enabled" class="ma-4" label="Enabled"/>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="save" :disabled="editedItem.password!==editedItem.password2">Save</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>

                <template v-slot:item="{item}">
                    <tr>
                        <td class="wider">
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <span :class="{ disabled: !item.enabled }" v-on="on">    {{ item.username }}</span>
                                </template>
                                <span v-if="!item.enabled">Disabled</span>
                            </v-tooltip>

                        </td>
                        <td>

                            <v-chip outlined small color="red" v-if="item.admin">
                                ADMIN
                            </v-chip>
                            <v-chip outlined small color="blue lighten-1" v-else>
                                USER
                            </v-chip>
                        </td>
                        <td>
                            <v-icon
                                    small
                                    class="mr-2"
                                    @click="editItem(item)"
                            >
                                edit
                            </v-icon>
                            <v-icon
                                    small
                                    @click="deleteItem(item)"
                            >
                                delete
                            </v-icon>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-container>
    </v-container>
</template>

<script>
    import {default as userApi} from '@/apis/user.ts'
    import Help from '@/views/Help.vue'
    import Error from '@/views/Error.vue'

    export default {
        name: "User",
        components: {Help, Error},
        data: function () {
            return {
                queryMessage: "",
                dialog: false,
                editedIndex: -1,
                editedItem: {
                    username: "",
                    password: "",
                    password2: "",
                    enabled: false,
                    admin: false
                },
                data: [],
                headers: [
                    {
                        text: "Name",
                        align: 'center',
                        sortable: true,
                        value: "username",
                    },
                    {
                        text: "Role",
                        align: 'center',
                        sortable: false,
                        value: "role",
                    },
                    {
                        text: "Action",
                        align: 'center',
                        sortable: false,
                        value: "action",
                    },
                ],
                error: null,
                editError: null,
                loading: false
            }
        },
        mounted() {
            this.loadUsers()
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
            },
        },

        watch: {
            dialog(val) {
                val || this.close()
            },
        },
        methods: {
            loadUsers() {
                this.loading = true;
                userApi.getAll()
                    .then(response => {
                        this.data = response.data.map((element) => {
                            return {
                                id: element.id,
                                username: element.username,
                                enabled: element.enabled,
                                admin: element.authorities.some(
                                    (authority) => {
                                        return (authority.name === "ROLE_ADMIN")
                                    })
                            }
                        })
                    })
                    .catch(error => {
                        this.$log.debug(error)
                        this.error = "Failed to load users"
                        this.$store.dispatch('updateUser')
                    })
                    .finally(() => this.loading = false)
            },

            editItem(item) {
                this.editedIndex = this.data.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem(item) {
                if (confirm('Are you sure you want to delete this item?')) {
                    userApi.removeForId(item.id)
                    this.loadUsers()
                }
            },


            close() {
                this.dialog = false
                this.editError = ""
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                }, 300)
            },

            save() {
                if (this.editedItem.password !== this.editedItem.password2) {
                    this.editError = "Sorry, the passwords should match."
                } else {
                    this.editError = ""
                    if (this.editedIndex > -1) {
                        userApi.updateForId(this.editedItem.id, this.editedItem).then(() => {
                            this.loadUsers() // That should be ok for small number of users
                        })
                        Object.assign(this.data[this.editedIndex], this.editedItem)
                        this.$log.debug("TODO Saving a modified user is not supported yet.")
                    } else {
                        userApi.createNew(this.editedItem).then(() => {
                            this.loadUsers() // That should be ok for small number of users
                        })
                        this.data.push(this.editedItem)

                    }
                    this.close()
                }
            },
        }

    }
</script>

<style scoped>
    .disabled {
        text-decoration: line-through;
    }
</style>